<template>
  <div class="user-page min-h-full relative">
    <div class="h-90px w-90px mx-auto mt-40px">
      <img src="@/assets/new/avatar.png" class="w-90px h-90px" alt="" srcset="">
    </div>
    <div class="text-20px font-semibold text-center mt-16px">
      {{global.account | hiddenAddress}}
    </div>
    <div class="px-24px mt-28px" v-if="content.length>0">
      <div class="rounded-20px bg-gradient-to-br from-[#FEC200] to-[#DC4CF4] p-8px">
        <div class="px-12px mt-8px flex justify-between">
          <div class="flex items-center">
            <img class="w-20px h-20px" src="@/assets/new/volume-hig.png" alt="" srcset="">
            <div class="ml-6px text-16px font-semibold">{{$t('head.announcement')}}</div>
          </div>
          <div class="flex items-center"  v-if="content.length>0">
            <img @click="onReduce" class="w-16px h-16px" src="@/assets/new/arrow-left@2x.png" alt="" srcset="">
            <div class="mx-12px text-14px font-semibold">
              <span>{{contentCurrent + 1}}</span>
              <span class="opacity-70">/{{content.length}}</span>
            </div>
            <img @click="onPlus" class="w-16px h-16px" src="@/assets/new/arrow-right@2x.png" alt="" srcset="">
          </div>
        </div>
        <div class="mt-16px">
          <div class="bg-white rounded-20px p-14px text-[#333333] text-13px leading-15px min-h-92px">
            <template v-if="currentContent">
              {{currentContent.noticeContent}}
            </template>
            <template v-else>
              <div></div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-22px px-24px">
      <div class="box p-14px flex items-center" @click="$router.push('/community')">
        <img class="w-24px" src="@/assets/new/co.png" alt="" srcset="">
        <div class="ml-10px text-16px">{{$t('head.mycommunity')}}</div>
      </div>
    </div>
    <div class="mt-16px px-24px">
      <div class="box p-14px flex items-center"  @click="$router.push('/language')">
        <img class="w-24px" src="@/assets/new/la.png" alt="" srcset="">
        <div class="ml-10px text-16px">{{$t('head.language')}}</div>
      </div>
    </div>
    <div class="mt-16px px-24px">
      <div class="box p-14px flex items-center" @click="$router.push('/fenxiang')">
        <img class="w-24px" src="@/assets/new/in.png" alt="" srcset="">
        <div class="ml-10px text-16px">{{$t('yqhy')}}</div>
      </div>
    </div>
  </div>
</template>
<script>

import mixin from '@/mixins/index'
export default {
  data() {
    return {
      invite: '',
      yaoqing: '',
      jihuo: false,
      geren: '0',
      tuandui: '0',
      nftpower:'0',
      rewards: 0,
      level: '',
      content:[],
      contentCurrent:0
    }
  },
  mixins: [mixin],
  computed:{
    currentContent(){
      if(this.content.length>0)
      return this.content[this.contentCurrent]
      else return null
    }
  }, 
  watch: {
    'global.account'() {
      console.log(this.$i18n.locale, 'lg')
      this.yaoqing = window.location.hash.substring(3)
      if (window.location.hash.substring(3) == '') {
        this.yaoqing = ''
      }
      this.invite = window.location.href + '?' + this.global.account
      this.IPHInvitation_contract = new this.global.web3.eth.Contract(
        this.poolabi.iphInvitation_abi,
        this.pool.iphInvitation
      )
      this.IPHpool_contract = new this.global.web3.eth.Contract(
        this.poolabi.iphpool_abi2,
        this.pool.iphpool2
      )
      this.NFTPool_contract = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        this.pool.iphpool1
      )
      // this.gethash()
      this.registerbool()
      // this.getNFTHashPower()
      // this.getRewards();
    },
  },
  mounted(){
    this.init()
  },
  methods: {
    onReduce(){
      if(this.contentCurrent>0){
        this.contentCurrent--
      }
    },
    onPlus(){
      if(this.contentCurrent<this.content.length-1){
        this.contentCurrent++
      }
    },
    init(){
      this.$axios.get(`${this.gonggao}/${this.$i18n.locale}`).then((res) => {
        this.content = res.data.data;
      });
    },  
    async getRewards() {
      if (!this.global.account) return
      const { data } = await this.$axios.get(
        process.env.VUE_APP_BASE_API + '/api/reward/' + this.global.account
      )
      this.rewards = data?.amt ?? 0
    },
    gethash() {
      this.IPHpool_contract.methods
        .getBalanceOfHash(this.global.account)
        .call()
        .then((res) => {
          console.log(res, 'getBalanceOfHashgetBalanceOfHash')
          this.geren = (res.poolHash / 1e18).toFixed(3)
          this.tuandui = (res.teamHash / 1e18).toFixed(3)
        })
      // todo 获取用户的奖励算力 http
    },
    getNFTHashPower(){
       this.NFTPool_contract.methods.balanceOf(this.global.account).call().then((res)=>{
         this.nftpower = (res / 1e18).toFixed(3)
       })
    },
    registerbool() {
      this.IPHInvitation_contract.methods
        .isExists(this.global.account)
        .call()
        .then((res) => {
          this.jihuo = res
        })
      this.IPHInvitation_contract.methods
        .Users(this.global.account)
        .call()
        .then((res) => {
          this.level = res.level
        })
    },
    open() {
      this.$prompt('', this.$i18n.t('head.activeAccount'), {
        inputValue: this.yaoqing,
        inputPlaceholder: this.$i18n.t('head.enterInviter'),
        confirmButtonText: this.$i18n.t('head.confirm'),
        cancelButtonText: this.$i18n.t('head.cancel'),
        showClose: false,
      })
        .then((value) => {
          console.log(value, 'valuevaluevaluevaluevalue')
          if (
            value.value == '' ||
            (value.value == this.$i18n.t('head.noInviter') &&
              value.value.length != 42)
          ) {
            return this.$toast(this.$i18n.t('head.noInviterFail'))
          }
          this.IPHInvitation_contract.methods
            .register(value.value)
            .send({
              from: this.global.account,
            })
            .then((res) => {
              this.$toast('SUCCESS')
              this.$router.go(0)
            })
            .catch((err) => {
              this.$toast(err)
            })
        })
        .catch(() => {})
    },
    playdrawer() {
      this.drawer = true
    },
    go(url){
      if(!url) return this.$toast(this.$t('head.dev'))
      window.location.href = url
    },
    routergo(item) {
      this.drawer = false
      if (item.dev) return this.$toast(this.$t('head.dev'))

      if (item.title == 'head.pledgeLoan') {
        return this.$toast(this.$i18n.t('head.notOpen'))
      }
      if (item.title == 'head.NFTBox') {
        window.location.href = 'http://myunic.dominonft.com/'
      }
      if (item.title == 'head.NFTTrading') {
        window.location.href =`${this.nfturl}/my-nft`
      }

      if (item.path == 'pancakeswap') {
        return (window.location.href = `https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=${this.pool.iph}`)
      }
      if (item.path == 'voting') {
        window.location.href = 'http://vote.dominonft.finance/'
      }

      if (item.path == 'kaiyuan') {
        window.location.href = 'https://github.com/dominonft/DominoNFT'
      } else {
        this.$router.push({
          path: item.path,
          // query: { userId: "/uploadppt/shenji.pdf" },
        })
      }
    },
    handleSuccess() {
      //成功
      this.$toast(this.$i18n.t('head.copySuccess'))
    },
    handleError() {
      //失败
      this.$toast(this.$i18n.t('head.copyFail'))
    },
  },
}
</script>
<style lang="scss" scoped>
.user-page{
  background-image: url('~@/assets/new/user-bg.png');
  background-repeat: no-repeat;
  background-size: 775px 775px;
  min-height: 100%;
  border-top: 1px solid transparent;
}
.box{
  backdrop-filter: saturate(180%) blur(2px); //毛玻璃效果
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.21),
    rgba(255, 255, 255, 0.1)
  );
  border-radius: 16px;
}
</style>